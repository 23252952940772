import { useContext, useState } from "react";
import Layout from "../components/layout";
import { WebsiteContext } from "../components/website-context";

export default function Home() {
    const { text } = useContext(WebsiteContext);
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const displayModal = (e) => {
        setModalImage(e.target.src);
        setShowModal(true);
    }

    return  <Layout title={text["home_title"]} description={text["home_description"]} mainImage={"img/main.jpg"}>
                <div className="mt-8">
                    {
                        text["home_text_1"]?.map((txt, idx) => {
                            return txt.title ?
                                        <h2 key={idx} className="text-2xl uppercase mt-4 mb-4">{txt.title}</h2>
                                    :
                                        <p key={idx}>{txt.text}</p>
                        })
                    }

                    {
                        showModal &&
                            <div className="fixed z-40 top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center opacity-0 transition-opacity" style={{opacity: 1}} onClick={() => setShowModal(false)}>
                                <div className="max-w-3xl max-h-3xl">
                                    <img src={modalImage} alt="" className="max-w-full max-h-full" />
                                    <button onClick={() => setShowModal(false)} className="absolute top-0 right-0 bg-white p-2 rounded-full m-4">X</button>
                                </div>
                            </div>
                    }

                    <div className="flex justify-between gap-10 my-8">
                        <div><img src="/img/montage-1.jpg" alt="" onClick={e => displayModal(e)} className="hover:cursor-pointer hover:opacity-50 transition shrink" /></div>
                        <div><img src="/img/montage-2.jpg" alt="" onClick={e => displayModal(e)} className="hover:cursor-pointer hover:opacity-50 transition shrink"/></div>
                        <div><img src="/img/montage-3.jpg" alt="" onClick={e => displayModal(e)} className="hover:cursor-pointer hover:opacity-50 transition shrink" /></div>
                    </div>
                    <div className="flex justify-between gap-10 my-8">
                        <div><img src="/img/montage-4.jpg" alt="" onClick={e => displayModal(e)} className="hover:cursor-pointer hover:opacity-50 transition shrink" /></div>
                        <div><img src="/img/montage-5.jpg" alt="" onClick={e => displayModal(e)} className="hover:cursor-pointer hover:opacity-50 transition shrink" /></div>
                        <div><img src="/img/montage-6.jpg" alt="" onClick={e => displayModal(e)} className="hover:cursor-pointer hover:opacity-50 transition shrink" /></div>
                    </div>
                </div>
            </Layout>

}